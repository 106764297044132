import '../App.css';
import './payment.css';

import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import logo from "../Logo.svg";
import LetterByLetterText from "../components/LetterByLetterText";
import or from "./png/Content divider.svg"

function Payment() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState();
    const [showPaymentOptions, setShowPaymentOptions] = useState(false);
    const subscriptionCardRef = useRef(null);
    const [buttonWidth, setButtonWidth] = useState(0);

    console.log(id)

    // Prevent scaling
    useEffect(() => {
        const preventTouchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        };

        window.addEventListener('touchstart', preventTouchZoom, { passive: false });
        return () => {
            window.removeEventListener('touchstart', preventTouchZoom);
        };
    }, []);

    useEffect(() => {
        const preventZoom = (e) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', preventZoom, { passive: false });
        return () => {
            window.removeEventListener('wheel', preventZoom);
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);

    useEffect(() => {
        if (subscriptionCardRef.current) {
            setButtonWidth(subscriptionCardRef.current.offsetWidth);
        }
    }, [isLoading, selectedPlan]);

    async function fetchRedirectUrl(sum, method) {
        try {
            const data = {
                userId: id,
                sum: sum,
                method: method
            }

            // Затем выполняем запрос на создание URL
            const response = await axios.post('https://click-vpn.ru:8443/API/create_url', data);

            console.log(response)

            window.location.href = response.data
        } catch (error) {
            console.error('Ошибка при запросе на создание URL:', error);
        }
    }



    const handlePayment = (method, sum) => {

        console.log(method);

        console.log(sum);
        // Call your payment API here
        fetchRedirectUrl(sum, method);
    };

    const selectPlan = (plan) => {
        setSelectedPlan(plan);
        setShowPaymentOptions(true);
        navigator.vibrate(20);
    };

    return (
        <div className="Payment">
            <div className="loading-container">
                <img src={logo} className={`loading-logo ${isLoading ? 'slide-in' : 'move-right'}`} alt="Загрузка..." />
                {!isLoading && (
                    <div className="click-vpn">
                        <LetterByLetterText text="Click Pay" delay={40} />
                    </div>
                )}
            </div>
            {!isLoading && (
                <div className="subscription-container">
                    <div className="pay-text">Выбери подписку, которую хочешь подарить:</div>
                    <div className={`subscription-card highlight ${selectedPlan === 1000 ? 'selected' : ''}`} onClick={() => selectPlan(1000)}>
                        <div className="subscription-title">Подарить на 12 месяцев 🎁</div>
                        <div className="subscription-price">1000 рублей</div>
                        <div className="subscription-savings">С любовью и надолго ❤️‍🩹</div>
                    </div>
                    <div className={`subscription-card ${selectedPlan === 550 ? 'selected' : ''}`} onClick={() => selectPlan(550)}>
                        <div className="subscription-title">Подарить на 6 месяцев 🎁</div>
                        <div className="subscription-price">550 рублей</div>
                        <div className="subscription-savings">Это уже серьёзно</div>
                    </div>
                    <div
                        className={`subscription-card ${selectedPlan === 100 ? 'selected' : ''}`}
                        onClick={() => selectPlan(100)}
                        ref={subscriptionCardRef}
                    >
                        <div className="subscription-title">Подарить на 1 месяц 🎁</div>
                        <div className="subscription-price">100 рублей</div>
                        <div className="subscription-savings">Чтобы попробовать</div>
                    </div>
                    <span className="gift_text">
                        После оплаты тебе придёт специальная ссылка. Её нужно будет отправить человеку, которому ты хочешь подарить VPN.
                        <br/>
                        <br/>
                        После того, как он перейдёт по этой ссылке и запустит бота, мы автоматически продлим ему подписку.
                    </span>
                </div>
            )}
            {showPaymentOptions && (
                <div className="payment-options">
                    <button className="payment-button1" onClick={() => handlePayment('card', selectedPlan)} style={{ width: buttonWidth }}>Оплатить картой РФ / СБП 💳</button>
                    <img className={'or'} src={or} alt="or" />
                    <button className="payment-button2" onClick={() => handlePayment('crypto', selectedPlan)} style={{ width: buttonWidth }}>Оплатить криптовалютой 🪙💱</button>
                </div>
            )}
        </div>
    );
}

export default Payment;
