import React, { useEffect, useState } from 'react';
import '../New_lk.css';
import './location.css';

const tg = window.Telegram.WebApp;

function Location() {

    tg.setHeaderColor('#1A659E');
    tg.MainButton.color = "#1A659E";
    tg.MainButton.setParams({
        text: 'Выбрать локацию'
    })

    useEffect(() => {
        const preventTouchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        };
        window.addEventListener('touchstart', preventTouchZoom, { passive: false });
        return () => {
            window.removeEventListener('touchstart', preventTouchZoom);
        };
    }, []);

    useEffect(() => {
        const preventZoom = (e) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', preventZoom, { passive: false });
        return () => {
            window.removeEventListener('wheel', preventZoom);
        };
    }, []);

    const BackButton = tg.BackButton;
    BackButton.show();
    BackButton.onClick(function() {
        BackButton.hide();
    });
    tg.onEvent('backButtonClicked', function() {
        window.location.href = '/new_lk';
    });

    const locations = [
        { name: 'Германия', emoji: '🇩🇪' },
        { name: 'США', emoji: '🇺🇸' },
        { name: 'Австрия', emoji: '🇦🇹' },
        { name: 'Нидерланды', emoji: '🇳🇱' },
        { name: 'Россия', emoji: '🇷🇺' },
        { name: 'Литва', emoji: '🇱🇹' },
        { name: 'Латвия', emoji: '🇱🇻' },
        { name: 'Франция', emoji: '🇫🇷' },
        { name: 'Великобритания', emoji: '🇬🇧' },
        { name: 'Турция', emoji: '🇹🇷' },
        { name: 'Норвегия', emoji: '🇳🇴' },
        { name: 'Финляндия', emoji: '🇫🇮' },
        { name: 'Польша', emoji: '🇵🇱' },
        { name: 'Македония', emoji: '🇲🇰' },
        { name: 'Швеция', emoji: '🇸🇪' },
    ];

    const [selectedIndex, setSelectedIndex] = useState(null);

    const handleCardClick = (index) => {
        if (selectedIndex !== index) {
            setSelectedIndex(index);
            tg.HapticFeedback.impactOccurred('soft');
            tg.MainButton.show();
        }
    };

    return (
        <div className="lk">
            <div className="new-subscription-card">
                <div className="new-subscription-title">Выбери локацию для Outline:</div>
            </div>

            {locations.map((location, index) => (
                <div
                    className={`new-subscription-card-location ${selectedIndex === index ? 'selected' : ''}`}
                    key={index}
                    onClick={() => handleCardClick(index)}
                >
                    <div className="new-subscription-title">
                        {location.name} {location.emoji}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Location;
