import React, { useState, useEffect } from 'react';
import '../New_lk.css';
import logo from "../../Logo.svg";
import LetterByLetterText from "../../components/LetterByLetterText";
import Clipboard from "react-clipboard-animation";

const tg = window.Telegram.WebApp;

function Settings() {

    tg.setHeaderColor('#1A659E')

    /////////////////////////////
    // Отключение масштабирвоания
    /////////////////////////////

    useEffect(() => {
        const preventZoom = (e) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', preventZoom, { passive: false });
        return () => {
            window.removeEventListener('wheel', preventZoom);
        };
    }, []);
    /////////////////////////////
    /////////////////////////////

    const BackButton = tg.BackButton;
    BackButton.show();
    BackButton.onClick(function() {
        BackButton.hide();
        tg.HapticFeedback.impactOccurred('rigid')
    });
    tg.onEvent('backButtonClicked', function() {
        window.location.href = '/new_lk';
    });


    return (
        <div className="lk">
            <div className="new-subscription-card">
                <div className="new-subscription-title">
                    Настройки <div className="rotate-emoji">⚙️</div>
                </div>
                <p className="subscription-details">Инструкции по настройке:</p>
                <button className="new-subscription-button-settings">
                    <div>Получить инструкции</div>
                    <div>📋</div>
                </button>
                <p className="subscription-details">Помощь в решении проблем:</p>
                <button className="new-subscription-button-settings">
                    <div>Связаться с поддержкой</div>
                    <div>🧑‍💻</div>
                </button>
                <p className="subscription-details">Вы можете отключить автосписания:</p>
                <button className="new-subscription-button-settings">
                    <div>Отключить подписку</div>
                    <div>❌</div>
                </button>
            </div>
        </div>
    );
}

export default Settings;