    import React, { useEffect, useState } from 'react';
    import { useParams } from 'react-router-dom'; // Импортируйте useParams из 'react-router-dom'
    import axios from 'axios';
    import {useTelegram} from "../hooks/useTelegram"

    function PaymentRedirect() {
        const {tg} = useTelegram();
        const { id } = useParams(); // Получаем значение id из параметров маршрута
        const [redirectUrl, setRedirectUrl] = useState(null);

        useEffect(() => {
            async function fetchRedirectUrl() {
                try {
                    const data = {
                        userId: id,
                        sum: 99
                    }
                    // Выполняем tg.expand() сразу
                    const expandPromise = tg.expand();

                    // Затем выполняем запрос на создание URL
                    const response = await axios.post('https://click-vpn.ru:8443/API/create_url', data);

                    // Ожидаем завершения tg.expand()
                    await expandPromise;

                    setRedirectUrl(response.data);
                } catch (error) {
                    console.error('Ошибка при запросе на создание URL:', error);
                }
            }

            fetchRedirectUrl();
        }, [id]);

        useEffect(() => {
            if (redirectUrl) {
                // Выполняйте редирект на полный URL
                window.location.href = redirectUrl;
            }
        }, [redirectUrl]);

        return (
            <div>

            </div>
        );
    }

    export default PaymentRedirect;
