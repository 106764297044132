import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './New_lk.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../Logo.svg";
import LetterByLetterText from "../components/LetterByLetterText";
import Wiki from "../Wiki/Wiki";
import DropDown from "../components/DropDown";
import Load from "../components/Load";
import Switch from "./components/Switch";
import { Tooltip } from 'react-tooltip';
import premium from "../components/premium";

const tg = window.Telegram.WebApp;

function New_lk() {
    const [isLoading, setIsLoading] = useState(true);
    const [Premium, setPremium] = useState(false);
    const [payment, setPayment] = useState([]);
    const [PayDay, setPayDay] = useState([]);

    tg.setHeaderColor('#1A659E');
    tg.expand();

    useEffect(() => {
        const preventTouchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        };
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        window.addEventListener('touchstart', preventTouchZoom, { passive: false });
        return () => {
            window.removeEventListener('touchstart', preventTouchZoom);
        };
    }, []);

    useEffect(() => {
        const preventZoom = (e) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', preventZoom, { passive: false });
        return () => {
            window.removeEventListener('wheel', preventZoom);
        };
    }, []);

    useEffect(() => {
        const hapticFeedback = async () => {

            let haptics

            if (Premium === true) {
                haptics = 12
            } else {
                haptics = 9
            }

            await new Promise(resolve => setTimeout(resolve, 80));
            tg.HapticFeedback.impactOccurred('heavy');
            await new Promise(resolve => setTimeout(resolve, 2000));
            for (let i = 0; i < haptics; i++) {
                tg.HapticFeedback.impactOccurred('rigid');
                await new Promise(resolve => setTimeout(resolve, 40));
            }
        };
        hapticFeedback();
    }, [Premium]);

    useEffect(() => {
        const handleScroll = () => {
            const cards = document.querySelectorAll('.new-subscription-card');
            const scrollPos = window.scrollY;
            const minMargin = 1;
            const maxMargin = 15;

            cards.forEach((card, index) => {
                const newMargin = maxMargin - (scrollPos * 0.1);
                card.style.marginBottom = `${Math.max(newMargin, minMargin)}px`;
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const Prolong = () => {
        tg.HapticFeedback.notificationOccurred('success');
        window.open(`https://click-vpn.ru/new_payment/${tg.initDataUnsafe?.user?.id}`, "_blank", "noopener noreferrer");
    }

    const handleGift = () => {
        tg.HapticFeedback.notificationOccurred('success');
        window.open(`https://click-vpn.ru/new_payment_gift/${tg.initDataUnsafe?.user?.id}`, "_blank", "noopener noreferrer");
    }

    useEffect(() => {
        axios.get(`https://click-vpn.ru:8443/API/userAccount/${tg.initDataUnsafe?.user?.id}`)
            .then(response => {
                // Обработка успешного ответа
                setPayDay(response.data.date)
                setPremium(response.data.premium)
                setPayment(response.data.payment)

                setTimeout(() => {
                    setIsLoading(false);
                }, 2000)
            })
            .catch(error => {
                // Обработка ошибки
                console.error(error);
            })
    }, []);

    return (
        <div className="lk">
            <div className="new-loading-container">
                <img src={logo} className={`loading-logo ${isLoading ? 'slide-in' : 'move-right'}`} alt="Загрузка..." />
                {!isLoading && (
                    <div className="click-vpn">
                        {Premium ? (
                            <div className="new-li-text">
                                <LetterByLetterText text="Click PREMIUM" delay={40} />
                            </div>
                        ) : <LetterByLetterText text="Click VPN" delay={40} />}
                    </div>
                )}
            </div>

            {isLoading ? (
                <div></div>
            ) : (
                <>
                    {/* Карточка с информацией о подписке */}
                    <div className="new-subscription-card">
                        <div className="new-subscription-title">Подписка</div>
                        <p className="subscription-details">
                            {payment && (
                                <>Оплачено до </>
                            )}
                            {PayDay}
                        </p>
                        <div className="subscription-actions">
                            <button className="new-subscription-button" onClick={Prolong}>
                                Продлить 📅
                            </button>
                            <button className="new-subscription-button-1" onClick={handleGift}>
                                Подарить 🎁
                            </button>
                        </div>
                    </div>

                    {payment && (
                        <>

                            {/* Карточка с информацией о локации */}
                            <div className="new-subscription-card">
                                <div className="new-subscription-title">
                                    Outline
                                    <div id="help-icon-outline" className="help-icon"onClick={() => {
                                        tg.HapticFeedback.impactOccurred('rigid')
                                    }}>
                                        ?
                                    </div>
                                </div>
                                <Tooltip
                                    anchorId="help-icon-outline"
                                    place="bottom"
                                    className="custom-tooltip"
                                >
                                    <p>Outline работает на базе протокола ShadowSocks.</p>
                                    <p>Чтобы сменить локацию - нажми "Сменить".</p>
                                </Tooltip>
                                <p className="subscription-details">
                                    Локация: Нидерланды
                                    <i className="location" onClick={() => {
                                        tg.HapticFeedback.notificationOccurred('success');
                                        window.location.href = '/location'
                                    }}>
                                        Сменить &gt;
                                    </i>
                                </p>
                                <div className="subscription-actions-copy">
                                    <button className="new-subscription-button-copy" onClick={() => {
                                        tg.HapticFeedback.notificationOccurred('success');
                                    }}>
                                        Скопировать ключ доступа 📋
                                    </button>
                                </div>
                            </div>

                            {/* Карточка с информацией о VLESS */}
                            <div className="new-subscription-card">
                                <div className="new-subscription-title">
                                    <div>VLESS</div>
                                    <div id="help-icon-vless" className="help-icon"onClick={() => {
                                        tg.HapticFeedback.impactOccurred('rigid')
                                    }}>
                                        ?
                                    </div>
                                    <Tooltip
                                        anchorId="help-icon-vless"
                                        place="bottom"
                                        className="custom-tooltip"
                                    >
                                        <p>VLESS - более современный и быстрый протокол.</p>
                                        <p>Чтобы его подключить - нажми на переключатель справа.</p>
                                        <p>Чтобы выбрать лучший сервер - нажми "Подобрать локацию".</p>
                                    </Tooltip>
                                    <Switch currentServer='VLES'/>
                                </div>
                                <p className="subscription-details-location"onClick={() => {
                                    tg.HapticFeedback.notificationOccurred('success');
                                    window.location.href = '/test_location'
                                }}>Подобрать локацию ></p>
                                <div className="subscription-actions-copy">
                                    <button className="new-subscription-button-copy" onClick={() => {
                                        tg.HapticFeedback.notificationOccurred('success');
                                    }}>
                                        Скопировать ключ доступа 📋
                                    </button>
                                </div>
                            </div>

                            {/* Карточка с реферальной ссылкой */}
                            <div className="new-subscription-card">

                                <div className="new-subscription-title">Реферальная ссылка</div>
                                <p className="subscription-details">https://t.me/First_VPN_Click_bot?start=220815377</p>

                                <div className="subscription-actions">

                                    <button className="new-subscription-button-2" onClick={() => {
                                        tg.HapticFeedback.notificationOccurred('success');
                                    }}>

                                        Скопировать 📋

                                    </button>

                                    <button className="new-subscription-button-2" onClick={() => {
                                        tg.HapticFeedback.notificationOccurred('success');
                                        tg.shareToStory('https://core.telegram.org/file/464001576/10249/wikoQUNnrH4.112118/7b6c8d3366ada2615b')
                                    }}>

                                        Поделиться 📲

                                    </button>

                                </div>

                            </div>

                            {/* Карточка настроек */}
                            <div className="new-subscription-card-settings" onClick={() => {
                                tg.HapticFeedback.notificationOccurred('success');
                                window.location.href = '/settings';
                            }}>
                                <div className="new-subscription-title">Настройки</div>
                                <div className="new-arrow">></div>
                            </div>

                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default New_lk;
