import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ConnectVLESS = () => {
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const encodedUrl = params.get('url');
        const userAgent = window.navigator.userAgent;

        if (encodedUrl) {
            const url = decodeURIComponent(encodedUrl);
            console.log('Decoded URL:', url);

            let redirectUrl = '';

            if (/android/i.test(userAgent)) {
                redirectUrl = `v2rayng://install-config?url=${url}&name=ClickVPN`;
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                redirectUrl = `v2box://install-sub?url=${url}&name=ClickVPN`;
            } else if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
                redirectUrl = `v2box://install-sub?url=${url}&name=ClickVPN`;
            } else if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
                redirectUrl = `hiddify://install-config/?url=${url}`;
            } else {
                console.log('Unsupported OS');
            }

            if (redirectUrl) {
                window.location.href = redirectUrl;
            } else {
                console.log('No valid redirect URL found for the detected OS.');
            }
        } else {
            console.log('No URL provided');
        }
    }, [location]);

    return (
        <div>
        </div>
    );
};

export default ConnectVLESS;
